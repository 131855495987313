<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-30 18:04:41
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="biaodanxq">
        <div class="youce_xianshi" v-if="dianji_chuxian_youce" @click="dianji_chuxian_youce=false">
			<div class="youce_box" @click.stop>
				<p class="youce_box_tit">
					{{xiangqing.tit}}
					<span @click="dianji_chuxian_youce=false">X</span>
				</p>
				<div class="youce_box_touxiang">
					<div class="youce_box_touxiang_left">
						<!-- <img :src="xiangqing.img" alt=""> -->
						<p class="toux">{{xiangqing.touxiang_text}}
						</p>
						<div class="youce_box_touxiang_left_text">
							<p>{{xiangqing.name}}
								<span style="font-size:10px;background:rgb(230, 242, 254);color:rgb(50, 150, 250);padding:0.02rem;" v-if="flag_replace_write==1">{{replace_write_user_name+'代提交'}}</span>
							</p>
							<p>{{gai_shei_shenpi(dangqian_shenpi_ren)}}</p>
						</div>
					</div>
					<div class="youce_box_touxiang_right">
						<!-- <span v-if="(xiangqing.flow_id=='qIwxmsMTgv'||xiangqing.flow_id=='He5ln0bdDO')&&xiangxi_xinxi.active==1&&xiangqing.flow_status==4" @click="zuofei_anniu(xiangqing.flow_id)">作废</span>
						<span v-if="(xiangqing.flow_id=='VMCizuVOXb')&&xiangxi_xinxi.active==1&&xiangqing.flow_status==4" @click="zuofei_anniu(xiangqing.flow_id)">作废/红冲</span>
						<span v-if="(xiangqing.flow_id=='PLOQ9ku3Ip'&&xiangxi_xinxi.stage_funn!=5&&xiangxi_xinxi.stage_funn!=6)&&(xiangqing.flow_status==4||xiangqing.flow_status==2||xiangqing.flow_status==7)" @click="loudou_bianji(xiangqing.flow_id,'漏斗单')">编辑</span>
						<el-tooltip v-if="xiangqing.flow_status==1" class="item" effect="dark" content="撤销" placement="bottom">
							<i @click="dianji_chehui" class='el-icon-top-left'></i>
						</el-tooltip> -->
						<!-- <el-tooltip class="item" effect="dark" content="归档单据查看" placement="bottom">
							<i class='el-icon-view' @click="dakai_danju(xiangxi_xinxi)"></i>
						</el-tooltip> -->
						<!-- <el-tooltip class="item" effect="dark" content="提示文字" placement="bottom">
							<i class='el-icon-document'></i>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="打印" placement="bottom">
							<i class='el-icon-printer'></i>
						</el-tooltip> -->
						<el-tooltip class="item" v-if="xiangxi_xinxi.length!=0" effect="dark" content="下载" placement="bottom">
							<i class='el-icon-download'></i>
							<i class='el-icon-download' @click="dianji_xiazai_danju"></i>
						</el-tooltip>
					</div>
				</div>
				<div class="youce_xiangxi_xinxi" v-if="xiangxi_xinxi.length!=0">
					<p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
					<transition name="el-zoom-in-top">
						<div v-show="xiangqing_zhankai" class="transition-box">
							<!-- {{xiangxi_xinxi}} -->
							<p v-if="xiangxi_xinxi.cust_name!==undefined">客户名称：{{xiangxi_xinxi.cust_name}}</p>
							<p v-if="xiangxi_xinxi.file_no!==undefined">归档号：{{xiangxi_xinxi.file_no}}</p>
							<p v-if='xiangxi_xinxi.staff_name!==undefined'>负责人：{{xiangxi_xinxi.staff_name}}</p>
							<p v-if="xiangxi_xinxi.dept_name!==undefined">部门1：{{xiangxi_xinxi.dept_name}}</p>
							<div v-if="xiangqing.flow_id=='7TvJHPyovM'">
								<ul>
									<li v-for='(i,index) in xiangxi_xinxi.contract_detail' :key="index">
										<p>决策人({{index+1}})</p>
										<p v-if="i.name!==undefined">姓名：{{i.name}}</p>
										<p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
										<p v-if="i.dept!==undefined">部门1：{{i.dept}}</p>
										<p v-if="i.post!==undefined">职务：{{i.post}}</p>
										<p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
										<p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
										<p v-if="i.birthday!==null&&i.birthday!==''&&i.birthday!==undefined">生日：{{i.birthday}}</p>
										<p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
										<p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
										<p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
										<p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
										<p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.province!==undefined">省份：{{xiangxi_xinxi.province}}</p>
								<p v-if="xiangxi_xinxi.city!==undefined">城市：{{xiangxi_xinxi.city}}</p>
								<p v-if="xiangxi_xinxi.industry!==undefined">行业：{{xiangxi_xinxi.industry}}</p>
								<p v-if="xiangxi_xinxi.source!==undefined">客户来源：{{xiangxi_xinxi.source}}</p>
								<p v-if="xiangxi_xinxi.level!==undefined">客户等级：{{xiangxi_xinxi.level}}</p>
								<p v-if="xiangxi_xinxi.cat_name!==undefined">客户标签：{{xiangxi_xinxi.cat_name}}</p>
								<p v-if="xiangxi_xinxi.biz_addr!==undefined">经营地址：{{xiangxi_xinxi.biz_addr}}</p>
								<p v-if="xiangxi_xinxi.rel_p_com!==undefined">母公司名称：{{xiangxi_xinxi.rel_p_com}}</p>
								<p v-if="xiangxi_xinxi.register_addr!==undefined">注册地址：{{xiangxi_xinxi.register_addr}}</p>
								<p v-if="xiangxi_xinxi.legal_name!==undefined">法定代表人：{{xiangxi_xinxi.legal_name}}</p>
								<p v-if="xiangxi_xinxi.uscc!==undefined">营业执照号：{{xiangxi_xinxi.uscc}}</p>
								<p v-if="xiangxi_xinxi.register_capital!==undefined">注册资本：{{xiangxi_xinxi.register_capital}}</p>
								<p v-if="xiangxi_xinxi.register_date!==undefined">成立日期：{{xiangxi_xinxi.register_date}}</p>
								<p v-if="xiangxi_xinxi.biz_term!==undefined">营业期限：{{xiangxi_xinxi.biz_term}}</p>
								<p v-if="xiangxi_xinxi.biz_scope!==undefined">营业范围：{{xiangxi_xinxi.biz_scope}}</p>
								<p v-if="xiangxi_xinxi.biz_lic_pic!==undefined">营业执照：<img @click="tupian_dianji(xiangxi_xinxi.biz_lic_pic)" class="tupian_kuang" :src="xiangxi_xinxi.biz_lic_pic" alt=""></p>
							</div>
							<div v-if="xiangqing.flow_id=='Z7VDOK9HGu'">
								<p v-if="xiangxi_xinxi.visit_type!==undefined">拜访方式：{{xiangxi_xinxi.visit_type}}</p>
								<p v-if="xiangxi_xinxi.visit_time!==undefined">拜访时间：{{xiangxi_xinxi.visit_time}}</p>
								<p v-if="xiangxi_xinxi.contact!==undefined">决策人：{{xiangxi_xinxi.contact}}</p>
								<p v-if="xiangxi_xinxi.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(xiangxi_xinxi.current_stage)}}</p>
								<p v-if="xiangxi_xinxi.next_stage!==undefined">预计下一漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.next_stage)}}</p>
								<p v-if="xiangxi_xinxi.remarks!==undefined">备注：{{xiangxi_xinxi.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='453y6pLD52'">
								<p v-if="xiangxi_xinxi.start_time!==undefined">开始时间：{{xiangxi_xinxi.start_time}}</p>
								<p v-if="xiangxi_xinxi.end_time!==undefined">结束时间：{{xiangxi_xinxi.end_time}}</p>
								<p v-if="xiangxi_xinxi.hour_long!==undefined">时长：{{xiangxi_xinxi.hour_long}}小时</p>
								<p v-if="xiangxi_xinxi.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(xiangxi_xinxi.current_stage)}}</p>
								<p v-if="xiangxi_xinxi.funn_stage_change_flag!==undefined">漏斗阶段变化：{{xiangxi_xinxi.funn_stage_change_flag}}</p>
								<p v-if="xiangxi_xinxi.funn_stage!==undefined">漏斗达成阶段：{{jieduan_hanzi(xiangxi_xinxi.funn_stage)}}</p>
								<p v-if="xiangxi_xinxi.change_cause!==undefined">阶段变化原因：{{xiangxi_xinxi.change_cause}}</p>
								<p v-if="xiangxi_xinxi.remarks!==undefined">备注：{{xiangxi_xinxi.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='PLOQ9ku3Ip'">
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.stage_funn!==undefined">漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.stage_funn)}}</p>
								<p v-if="xiangxi_xinxi.stage_before_back!=undefined&&xiangxi_xinxi.flag_back==1">退回前漏斗阶段：{{jieduan_hanzi(xiangxi_xinxi.stage_before_back)}}</p>
								<p v-if="xiangxi_xinxi.amt_funn!==undefined">漏斗金额：{{xiangxi_xinxi.amt_funn}}</p>
								<p v-if="xiangxi_xinxi.date_con_est!==undefined">预计合同日期：{{xiangxi_xinxi.date_con_est}}</p>
								<p v-if="xiangxi_xinxi.rate_funn_disc!==undefined">漏斗折算比例：{{xiangxi_xinxi.rate_funn_disc}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='Eb3n7CKQzi'">
								<p v-if="xiangxi_xinxi.help_order_flag!==undefined">是否为助单行为：{{xiangxi_xinxi.help_order_flag}}</p>
								<p v-if="xiangxi_xinxi.disp_staff_name!==undefined">派工人员：{{xiangxi_xinxi.disp_staff_name}}</p>
								<p v-if="xiangxi_xinxi.help_order_type!==undefined">助单类型：{{xiangxi_xinxi.help_order_type}}</p>
								<p v-if="xiangxi_xinxi.biz_trip_type!==undefined">出差类型：{{xiangxi_xinxi.biz_trip_type}}</p>
								<p v-if="xiangxi_xinxi.addr!==undefined">地址：{{xiangxi_xinxi.addr}}</p>
								<p v-if="xiangxi_xinxi.contact!==undefined">决策人：{{xiangxi_xinxi.contact}}</p>
								<p v-if="xiangxi_xinxi.mobile!==undefined">手机：{{xiangxi_xinxi.mobile}}</p>
								<p v-if="xiangxi_xinxi.trip_start_time!==undefined">开始时间：{{xiangxi_xinxi.trip_start_time}}</p>
								<p v-if="xiangxi_xinxi.trip_end_time!==undefined">结束时间：{{xiangxi_xinxi.trip_end_time}}</p>
								<p v-if="xiangxi_xinxi.hour_long!==undefined">时长：{{xiangxi_xinxi.hour_long}}小时</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
										<p v-if="i.disp_item!==undefined">派工项目：{{i.disp_item}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.remarks!==undefined">备注：{{xiangxi_xinxi.remarks}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='qIwxmsMTgv'">
								<p v-if="xiangxi_xinxi.date_con!==undefined">合同日期：{{xiangxi_xinxi.date_con}}</p>
								<p v-if="xiangxi_xinxi.belong_to_organ!==undefined">所属公司：{{xiangxi_xinxi.belong_to_organ}}</p>
								<p v-if="xiangxi_xinxi.industry!==undefined">行业：{{xiangxi_xinxi.industry}}</p>
								<p v-if="xiangxi_xinxi.con_type!==undefined">合同类型：{{xiangxi_xinxi.con_type}}</p>
								<p v-if="xiangxi_xinxi.con_valid_term!==undefined">合同有效时间：{{xiangxi_xinxi.con_valid_term}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_list" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_con!==undefined">合同金额：{{xiangxi_xinxi.amt_con}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.amt_list" :key="index">
										<p>开票({{index+1}})</p>
										<p v-if="i.date_inv_est!==undefined">预计发票日期：{{i.date_inv_est}}</p>
										<p v-if="i.amt_inv_est!==undefined">预计发票金额：{{i.amt_inv_est}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_con!==undefined">预计发票金额合计：{{xiangxi_xinxi.amt_con}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='VMCizuVOXb'">
								<p v-if="xiangxi_xinxi.belong_to_organ!==undefined">所属公司：{{xiangxi_xinxi.belong_to_organ}}</p>
								<p v-if="xiangxi_xinxi.date_inv!==undefined">发票日期：{{xiangxi_xinxi.date_inv}}</p>
								<p v-if="xiangxi_xinxi.inv_type!==undefined">发票类型：{{xiangxi_xinxi.inv_type}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_list" :key="index">
										<p>发票内容({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
										<p v-if="i.price_unit_no_tax!==undefined">不含税折后单价：{{i.price_unit_no_tax}}</p>
										<p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
										<p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}%</p>
										<p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
										<p v-if="i.amt_inv_detail!==undefined">发票金额：{{i.amt_inv_detail}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_no_tax_sum!==undefined">合计不含税金额：{{xiangxi_xinxi.amt_no_tax_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_tax_sum!==undefined">合计税额：{{xiangxi_xinxi.amt_tax_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_inv!==undefined">合计发票金额：{{xiangxi_xinxi.amt_inv}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_sum!==undefined">累计发票金额：{{xiangxi_xinxi.amt_inv_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_un!==undefined">合同未开发票金额：{{xiangxi_xinxi.amt_inv_un}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.amt_list" :key="index">
										<p>回款({{index+1}})</p>
										<p v-if="i.date_rec_est!==undefined">预计回款日期：{{i.date_rec_est}}</p>
										<p v-if="i.amt_rec_est!==undefined">预计回款金额：{{i.amt_rec_est}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.cust_name!==undefined">客户：{{xiangxi_xinxi.cust_name}}</p>
								<p v-if="xiangxi_xinxi.tax_no!==undefined">纳税识别号：{{xiangxi_xinxi.tax_no}}</p>
								<p v-if="xiangxi_xinxi.mobile!==undefined">电话：{{xiangxi_xinxi.mobile}}</p>
								<p v-if="xiangxi_xinxi.addr!==undefined">地址：{{xiangxi_xinxi.addr}}</p>
								<p v-if="xiangxi_xinxi.bank!==undefined">开户行：{{xiangxi_xinxi.bank}}</p>
								<p v-if="xiangxi_xinxi.bank_acct!==undefined">开户行账号：{{xiangxi_xinxi.bank_acct}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='He5ln0bdDO'">
								<p v-if="xiangxi_xinxi.date_rec!==undefined">回款日期：{{xiangxi_xinxi.date_rec}}</p>
								<p v-if="xiangxi_xinxi.inv_code!==undefined">发票代码：{{xiangxi_xinxi.inv_code}}</p>
								<p v-if="xiangxi_xinxi.inv_no!==undefined">发票号码：{{xiangxi_xinxi.inv_no}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品回款({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_rec!==undefined">回款金额：{{xiangxi_xinxi.amt_rec}}</p>
								<p v-if="xiangxi_xinxi.amt_rec_sum!==undefined">累计回款金额：{{xiangxi_xinxi.amt_rec_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_inv_sum!==undefined">累计开票金额：{{xiangxi_xinxi.amt_inv_sum}}</p>
								<p v-if="xiangxi_xinxi.amt_rec_unrec!==undefined">应收金额：{{xiangxi_xinxi.amt_rec_unrec}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='cvRyWGiUQH'">
								<p v-if="xiangxi_xinxi.start_time!==undefined">开始时间：{{xiangxi_xinxi.start_time}}</p>
								<p v-if="xiangxi_xinxi.end_time!==undefined">结束时间：{{xiangxi_xinxi.end_time}}</p>
								<p v-if="xiangxi_xinxi.hour_long!==undefined">时长：{{xiangxi_xinxi.hour_long}}小时</p>
								<p v-if="xiangxi_xinxi.go_out_cause!==undefined">外出事由：{{xiangxi_xinxi.go_out_cause}}</p>
								<p v-if="xiangxi_xinxi.year_hour_long!==undefined">年累计外出时长：{{xiangxi_xinxi.year_hour_long}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='GBh7N2jMsi'">
								<p v-if="xiangxi_xinxi.remarks!==undefined">出差备注：{{xiangxi_xinxi.remarks}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.trip_detail" :key="index">
										<p>行程({{index+1}})</p>
										<p v-if="i.transport!==undefined">交通工具：{{i.transport}}</p>
										<p v-if="i.leave_city!==undefined">出发城市：{{i.leave_city}}</p>
										<p v-if="i.arrive_city!==undefined">目的城市：{{i.arrive_city}}</p>
										<p v-if="i.start_time!==undefined">开始时间：{{i.start_time}}{{i.start_noon==1?'下午':'上午'}}</p>
										<p v-if="i.end_time!==undefined">结束时间：{{i.end_time}}{{i.end_noon==1?'下午':'上午'}}</p>
										<p v-if="i.hour_long!==undefined">时长（天）：{{i.hour_long}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.trip_days!==undefined">出差天数：{{xiangxi_xinxi.trip_days}}</p>
								<p v-if="xiangxi_xinxi.year_trip_days!==undefined">年累计出差天数：{{xiangxi_xinxi.year_trip_days}}</p>
							</div>
							<div v-if="xiangqing.flow_id=='H49d4edc3T'">
								<p v-if="xiangxi_xinxi.date_deliver!==undefined">日期：{{xiangxi_xinxi.date_deliver}}</p>
								<ul>
									<li v-for="(i,index) in xiangxi_xinxi.prod_detail" :key="index">
										<p>产品明细({{index+1}})</p>
										<p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
										<p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
										<p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
										<p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
										<p v-if="i.type!==undefined">型号：{{i.type}}</p>
										<p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
										<p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
										<p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
										<p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
										<p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
									</li>
								</ul>
								<p v-if="xiangxi_xinxi.amt_deliver!==undefined">交付金额：{{xiangxi_xinxi.amt_deliver}}</p>
							</div>
							<!-- <ul>
								<li v-for="(i,index) in xiangqing.extra" :key="index">
									<p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p>
								</li>
							</ul> -->
							<ul>
								<li v-for="(i,index) in xiangxi_xinxi.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
						</div>
					</transition>
				</div>
				<div class="youce_xiangxi_xinxi" v-if="xiangxi_xinxi.length==0">
					<p class="xiangxi_tit" style="font-size:0.26rem;color:red;" v-if="xiangqing.flow_id=='7TvJHPyovM'">该客户已被删除</p>
					<p class="xiangxi_tit" style="font-size:0.26rem;color:red;" v-if="xiangqing.flow_id=='PLOQ9ku3Ip'">该漏斗单已被修改，请去最新的漏斗单查询</p>
				</div>
				<div class="youce_box_con">
					<p v-if="xiangqing.juese!=undefined&&xiangqing.juese.length!=0">当前角色：<span>{{xiangqing.juese}}</span></p>
					<p v-if="xiangqing.bumen!=undefined&&xiangqing.bumen.length!=0">所在部门：<span>{{xiangqing.bumen}}</span></p>
					<!-- <p>审批编号：<span>{{xiangqing.flow_no}}</span></p> -->
					<p v-if="xiangqing.time!=undefined&&xiangqing.time.length!=0">发起时间：<span>{{xiangqing.time}}</span></p>
					<p v-if="xiangqing.flow_desc!=undefined&&xiangqing.flow_desc.length!=0">内容摘要：<span>{{xiangqing.flow_desc}}</span></p>
				</div>
				<!-- <ul class="liucheng">
					<li>
						<div class="li_div">
							<p class="li_div_touxiang">{{xiangqing.name[0]}}</p>
							<div>
								<p>发起申请</p>
								<p>{{xiangqing.user_id==this.$jichuxinxi().user_id?'我':xiangqing.name}}</p>
							</div>
						</div>
						<p class="liucheng_time">{{xiangqing.time}}</p>
					</li>
					<li v-for="(i,index) in liucheng_list" :key="index">
						<div class="li_div">
							<p class="li_div_touxiang">{{i.operate_name[0]}}</p>
							<div>
								<p>{{i.user_type==2?'审批人':"抄送人"}}</p>
								<p>{{i.operate_name}}({{i.zhuangtai}})</p>
							</div>
						</div>
						<p class="liucheng_time">{{i.operate_time}}</p>
					</li>
				</ul> -->
				<ul class="liucheng">
					<li class="liucheng_li" v-for="(i,index) in liucheng_list" :key="index">
						<div class="liucheng_li_box" v-if="!i.liebiao">
							<div class="liucheng_ren" v-if="!i.pinglun">
								<div class="liucheng_ren_box">
									<p class="liucheng_touxiang">{{i.staff_name[0]}}</p>
									<span :style="i.appr_status==0||i.appr_status==4||i.appr_status==5||i.appr_status==6||i.appr_status==8?'background:rgb(21, 188, 131)':(i.appr_status==1?'background:rgb(255, 148, 62)':(i.appr_status==2||i.appr_status==3?'background:red':'background:rgb(227, 227, 228)'))"></span>
									<div>
										<!-- <p>{{i.user_type==0?'发起申请':(i.user_type==1?'审批人':'抄送人')}}</p> -->
										<p>{{i.user_type==0?'发起申请':(i.user_type==1?'审批人':'抄送人')}}</p>
										<p>{{i.user_id==$jichuxinxi().user_id?'我':i.staff_name}}({{zhuangtai_name(i.appr_status,i.user_type,i.auto_appr_flag,i.operate_time)}})</p>
									</div>
								</div>
								<p class="liucheng_time">{{i.operate_time}}</p>
							</div>
							<p v-if="i.appr_opn&&!i.pinglun" class="liucheng_pinglun">“{{i.appr_opn}}”</p>
							<div v-if="i.pinglun" class="pinglun_zhanshi">
								<div>
									<p>
										<span>{{i.cmt_user}}</span>添加了评论
									</p>
									<span class="shijian">{{i.operate_time}}</span>
								</div>
								<p class="pinglun_neirong">"{{i.cmt_desc}}"</p>
							</div>
						</div>
						<div class="liucheng_liebiao" v-if="i.liebiao">
							<div class="liucheng_lb_box">
								<div class="liucheng_liebiao_box">
									<p class="liucheng_touxiang">{{i.user_type==0?'发':(i.user_type==1?'审':'抄')}}</p>
									<span :style="i.appr_status==0||i.appr_status==4||i.appr_status==5||i.appr_status==6||i.appr_status==8?'background:rgb(21, 188, 131)':(i.appr_status==1?'background:rgb(255, 148, 62)':(i.appr_status==2||i.appr_status==3?'background:red':'background:rgb(227, 227, 228)'))"></span>
									<div>
										<p>{{i.user_type==0?'发起申请':(i.user_type==1?'审批人':'抄送人')}}</p>
										<p v-if="!i.zidong_tongguo">{{i.list.length}}人<s v-if="i.user_type!=2">{{i.appr_sort==1?'依次':(i.appr_sort==2?"会签":'或签')}}</s></p>
										<p v-if="i.zidong_tongguo">将自动通过</p>
									</div>
								</div>
								<p class="liucheng_time">{{i.operate_time}}</p>
							</div>
							<ul>
								<li v-for="(it,idx) in i.list" :key="idx">
									<p>{{it.staff_name[0]}}</p>
									<el-tooltip class="item" effect="dark" :content="it.staff_name" placement="bottom">
										<p>{{it.staff_name}}</p>
									</el-tooltip>
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<div class="youce_box_foot">
					<p @click="dianji_pinglun">评论</p>
					<!-- <p @click="dakai_bumen">转交</p>
					<p @click="dianji_tuihui">退回</p>
					<p @click="dianji_jujue">拒绝</p>
					<p @click="dianji_tongyi">同意</p> -->
				</div>
			</div>
		</div>
        <div class="pinglun" v-if="shuru_pinglun" @click="shuru_pinglun=false">
			<div class="box" @click.stop>
				<p class="tit">
					{{shuohua_txt}}
					<span @click="shuru_pinglun=false">X</span>
				</p>
				<textarea class="input" placeholder="请输入评论" v-model="pinglun_text"></textarea>
				<div class="foot">
					<span @click="dianji_fasong_pinglun">发送</span>
					<span @click="shuru_pinglun=false">取消</span>
				</div>
			</div>
		</div>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import { query_form_replace_write_user, query_customer_detail_form, generate_pdf_form, insert_flow_cmt, query_appr_detail, query_appr_step_list, query_flow_cmt_list } from '../../api/api'
import BiaodanXiangqing from './biaodanxq'
import tupian from '../bigphote/bigphote'
export default {
  name: 'biaodanxq',

  data () {
    return {
      dianji_chuxian_youce: false,
      xiangqing: {},
      xiangxi_xinxi: {},
      liucheng_list: [],
      xiangqing_zhankai: false,
      shuru_pinglun: false,
      pinglun_text: '',
      shuohua_txt: '',
      yanjing_zhanshi: false,
      dangqian_shenpi_ren: '',

      flag_replace_write: '0', // 1别人代写   0 自己写
      replace_write_user_name: '' // 别人代写的名字
    }
  },
  components: { BiaodanXiangqing, tupian },
  watch: {
    dianji_chuxian_youce: {
      handler (newValue, oldValue) {
        if (newValue == false) {
          this.xiangxi_xinxi = {}
          this.xiangqing_zhankai = false
          this.xiangqing = {}
        } else {
          // this.liucheng_jiekou()
        }
        // this.kehu_shifou_cunzai()
      },
      deep: true
    }
  },
  created () {},
  mounted () {},
  methods: {
    jichu (i) {
      this.dianji_chuxian_youce = true
      this.xiangqing.tit = i.name
      this.yanjing_zhanshi = false
      this.xiangqing.img = i.img
      this.xiangqing.zhuangtai = i.caozuo
      this.xiangqing.flow_status = i.flow_status
      this.xiangqing.name = i.start_user_name
      this.xiangqing.flow_no = i.flow_no
      this.xiangqing.flow_name = i.flow_name
      this.xiangqing.time = i.start_time
      this.xiangqing.flow_desc = i.flow_desc
      this.xiangqing.flow_id = i.flow_id
      this.xiangqing.user_id = i.start_user_id
      this.xiangqing.touxiang_text = i.start_user_name.substring(0, 1)

      query_appr_detail({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          flow_no: i.flow_no,
          flow_id: i.flow_id
        }
      }).then(res => {
        console.log(res, 'asdasdasd')
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.appr_info)
          const data = JSON.parse(res.data.body.appr_step)
          const datb = JSON.parse(res.data.body.staff)
          const xiangqing = JSON.parse(res.data.body.appr_form_data)
          console.log(xiangqing, 'ngdnd')
          console.log(data, 'asdasd')
          // let liucheng_list=[]
          // for(let a=1;a<data.length;a++){
          // 	this.$set(data[a],'zhuangtai',this.panduan_zhuangtai(data[a].action_status))
          // 	liucheng_list.push(data[a])
          // }
          // this.liucheng_list=liucheng_list
          console.log(datb, 'qweqwe')
          console.log(date, 'qweqwewe')
          this.xiangqing.bumen = datb.dept_name
          this.xiangqing.juese = datb.post_name
          xiangqing.zidingyi = xiangqing.extra != undefined ? JSON.parse(xiangqing.extra) : []
          this.xiangxi_xinxi = xiangqing
          // xiangqing.zidingyi.forEach(ite=>{
          // 	if(ite.type=='cust'&&ite.value_id!=undefined&&ite.value_id.length!=0){
          // 		if(this.xiangxi_xinxi.cust_id!=undefined&&this.xiangxi_xinxi.cust_id.length!=0){

          // 		}else{
          // 			xiangqing.cust_id=ite.value_id
          // 		}
          // 	}
          // })
          if (this.xiangxi_xinxi.cust_id != undefined && this.xiangxi_xinxi.cust_id.length != 0) {

          } else {
            for (let ia = 0; ia < xiangqing.zidingyi.length; ia++) {
              if (xiangqing.zidingyi[ia].type == 'cust') {
                xiangqing.cust_id = xiangqing.zidingyi[ia].value_id
                console.log(xiangqing.zidingyi[ia].value_id)

                break
              }
            }
          }
          if (xiangqing.cust_id != undefined && xiangqing.cust_id.length != 0) {
            this.yanjing_zhanshi = true
          } else {
            this.yanjing_zhanshi = false
          }
        } else if (res.data.code == 500) {} else if (res.data.code == 700) {
          this.yanjing_zhanshi = false
          this.xiangxi_xinxi = ''
        }
      })
      this.chaxun_pinglun()
    },
    chaxun_pinglun () {
      if (this.xiangxi_xinxi.length != 0) {
        query_form_replace_write_user({
          data: {
            ent_id: this.$ent_id(),
            flow_no: this.xiangqing.flow_no
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date) {
              this.flag_replace_write = date.flag_replace_write
              this.replace_write_user_name = date.replace_write_user_name
            } else {
              this.flag_replace_write = '0'
              this.replace_write_user_name = ''
            }
          } else if (res.data.code == 500) {}
        })
      }
      query_appr_step_list({
        data: {
          ent_id: this.$ent_id(),
          flow_id: this.xiangqing.flow_id,
          flow_no: this.xiangqing.flow_no
        }
      }).then(lc => {
        console.log(lc)
        if (lc.data.code == 200) {
          const lc_list = JSON.parse(lc.data.body.data)
          console.log(lc_list)
          console.log(lc.data.body.data)
          const list_zsz = []
          for (let i = 0; i < lc_list.length; i++) {
            if (lc_list[i].history_flag != undefined) {
              this.$set(lc_list[i], 'pinglun', false)
              this.$set(lc_list[i], 'liebiao', false)
              list_zsz.push(lc_list[i])
            } else {
              if (lc_list[i].user_type == 0) {
                this.$set(lc_list[i].list[0], 'pinglun', false)
                this.$set(lc_list[i].list[0], 'liebiao', false)
                list_zsz.push(lc_list[i].list[0])
              } else if (lc_list[i].user_type == 1) {
                if (lc_list[i].appr_sort == 1) {
                  for (let b = 0; b < lc_list[i].list.length; b++) {
                    this.$set(lc_list[i].list[b], 'pinglun', false)
                    this.$set(lc_list[i].list[b], 'liebiao', false)
                    list_zsz.push(lc_list[i].list[b])
                  }
                } else if (lc_list[i].appr_sort == 2) {
                  for (let b = 0; b < lc_list[i].list.length; b++) {
                    if (lc_list[i].list[b].appr_status == 4 || lc_list[i].list[b].appr_status == 3 || lc_list[i].list[b].appr_status == 6) {
                      this.$set(lc_list[i].list[b], 'pinglun', false)
                      this.$set(lc_list[i].list[b], 'liebiao', false)
                      list_zsz.push(lc_list[i].list[b])
                      lc_list[i].list.splice(b, 1)
                      b = b - 1
                    }
                  }

                  setTimeout(() => {
                    console.log(lc_list[i])
                  }, 0)
                  if (lc_list[i].list.length != 0) {
                    this.$set(lc_list[i], 'pinglun', false)
                    this.$set(lc_list[i], 'liebiao', true)
                    list_zsz.push(lc_list[i])
                  }
                } else if (lc_list[i].appr_sort == 3) {
                  for (let b = 0; b < lc_list[i].list.length; b++) {
                    if (lc_list[i].list[b].appr_status == 4 || lc_list[i].list[b].appr_status == 3 || lc_list[i].list[b].appr_status == 6) {
                      this.$set(lc_list[i].list[b], 'pinglun', false)
                      this.$set(lc_list[i].list[b], 'liebiao', false)
                      list_zsz.push(lc_list[i].list[b])
                      lc_list[i].list = []
                    }
                  }
                  if (lc_list[i].list.length != 0) {
                    this.$set(lc_list[i], 'pinglun', false)
                    this.$set(lc_list[i], 'liebiao', true)
                    list_zsz.push(lc_list[i])
                  }
                }
              } else if (lc_list[i].user_type == 2) {
                this.$set(lc_list[i], 'pinglun', false)
                this.$set(lc_list[i], 'liebiao', true)
                this.$set(lc_list[i], 'appr_status', lc_list[i].list[0].appr_status)
                this.$set(lc_list[i], 'operate_time', lc_list[i].list[0].operate_time)
                list_zsz.push(lc_list[i])
              }
            }
          }
          query_flow_cmt_list({
            data: {
              ent_id: this.$ent_id(),
              flow_no: this.xiangqing.flow_no
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              date.map(item => {
                this.$set(item, 'pinglun', true)
                this.$set(item, 'liebiao', false)
                this.$set(item, 'operate_time', item.cmt_time)
                list_zsz.push(item)
              })
              let you_sj = []
              for (let a = 0; a < list_zsz.length; a++) {
                if (list_zsz[a].operate_time != undefined) {
                  you_sj.push(list_zsz[a])
                }
              }
              you_sj = this.$func.ForwardRankingDate(you_sj, 'operate_time')
              let shenpi_ren = 'aa'
              for (let a = 0; a < list_zsz.length; a++) {
                if (list_zsz[a].operate_time == undefined) {
                  you_sj.push(list_zsz[a])
                  if (list_zsz[a].user_type == 1 && shenpi_ren == 'aa') {
                    // console.log(this.xiangxi_xinxi.zidingyi)
                    console.log(list_zsz[a])
                    this.dangqian_shenpi_ren = list_zsz[a]
                    shenpi_ren = 'bb'
                  }
                }
              }
              // for(let a=0;a<list2.length;a++){
              // 	you_sj.push(list2[a])
              // }
              you_sj.forEach(item => {
                if (item.list != undefined) {
                  const zidong_tongguo = []
                  item.list.forEach(ite => {
                    if (ite.auto_appr_flag == 1) {
                      zidong_tongguo.push('1111')
                    }
                  })
                  if (zidong_tongguo.length != 0 && zidong_tongguo.length == item.list.length) {
                    this.$set(item, 'zidong_tongguo', true)
                  }
                }
              })
              this.liucheng_list = you_sj
            } else if (res.data.code == 500) {}
          })
        } else if (lc.data.code == 500) {}
      })
    },
    gai_shei_shenpi (i) {
      console.log(i)
      if (i.list != undefined) {
        let aa = 'aa'
        i.list.forEach(item => {
          if (item.user_id == this.$jichuxinxi().user_id) {
            aa = 'bb'
          }
        })
        if (aa == 'aa') {
          return '等待审批中'
        } else {
          return '等待我审批'
        }
      } else {
        if (i.user_id == this.$jichuxinxi().user_id) {
          return '等待我审批'
        } else if (i.staff_name != undefined) {
          return '等待' + i.staff_name + '审批'
        } else {
          return this.xiangqing.zhuangtai
        }
      }
    },
    // 点击评论
    dianji_pinglun () {
      this.shuohua_txt = '评论'
      this.shuru_pinglun = true
    },
    // 点击发送评论
    dianji_fasong_pinglun () {
      if (this.shuohua_txt == '评论') {
        insert_flow_cmt({
          data: {
            ent_id: this.$ent_id(),
            flow_no: this.xiangqing.flow_no,
            cmt_user: this.$jichuxinxi().name,
            cmt_desc: this.pinglun_text
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.shuru_pinglun = false
            this.pinglun_text = ''
            this.chaxun_pinglun()
          } else if (res.data.code == 500) {}
        })
      }
    },
    jieduan_hanzi (i) {
      if (i == '1') {
        return '初步接触'
      } else if (i == '2') {
        return '需求确认'
      } else if (i == '3') {
        return '报价及方案展示'
      } else if (i == '4') {
        return '商务谈判'
      } else if (i == '5') {
        return '赢单'
      } else if (i == '6') {
        return '输单'
      }
    },
    zhuangtai_name (i, a, b, c) {
      if (i == 0) {
        if (this.flag_replace_write == 1) {
          return this.replace_write_user_name + '代提交'
        } else {
          return '发起审批'
        }
      } else if (i == 1 && a != 2) {
        return '审批中'
      } else if (i == 2) {
        return '已拒绝'
      } else if (i == 3) {
        return '已退回'
      } else if (i == 4 && (b == 0 || b == undefined)) {
        return '审批通过'
      } else if (i == 5) {
        return '已归档'
      } else if (i == 6) {
        return '已转交'
      } else if (i == 7) {
        return '已撤销'
      } else if (i == 8) {
        return '已抄送'
      }
      if (b == 1 && c == undefined) {
        return '审批将自动通过'
      } else if (b == 1 && c != undefined) {
        return '已自动通过'
      }
    },
    // 点击表单详情
    biaodan_xiangqing (i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i)
    },
    dianji_xiazai_danju () {
      // (xiangxi_xinxi)
      console.log(this.xiangqing)
      generate_pdf_form({
        data: {
          ent_id: this.$ent_id(),
          flow_id: this.xiangqing.flow_id,
          flow_name: this.xiangqing.flow_name,
          flow_no: this.xiangqing.flow_no,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const url = JSON.parse(res.data.body.data)
          console.log(url)
          this.$func.fujian_xiazai3(url, this.$jichuxinxi().ent_info.ent_name + this.xiangqing.flow_name)
        } else if (res.data.code == 500) {}
      })
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.biaodanxq {
    .youce_xianshi{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		margin: 0;
		z-index:100;
		.youce_box{
			position: absolute;
			right:0;
			top:0;
			width:5.8rem;
			padding: 0.32rem 0.2rem 0 0.2rem;
			box-shadow:-0.06rem 0 0.04rem 0 rgba(0,0,0,0.1);
			background:#fff;
			min-height: 96vh;
			.youce_box_tit{
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 0.18rem;
				font-weight: bold;
				color:#1A2533;
				span{
					font-size: 0.18rem;
					color:#999999;
					font-weight: 400;
					cursor: pointer;
				}
			}
			.youce_box_touxiang{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top:0.49rem;
				padding-bottom: 0.25rem;
				border-bottom:0.01rem solid #EEEEEE;
				.youce_box_touxiang_left{
					display: flex;
					align-items: center;
					img{
						height:0.8rem;
						width:0.8rem;
						border-radius: 50%;
					}
					.toux{
						height:0.8rem;
						width:0.8rem;
						border-radius: 50%;
						background: #1890ff;
						color:#fff;
						font-size: 0.4rem;
						text-align: center;
						line-height: 0.8rem;
						font-family: NSimSun;
					}
					.youce_box_touxiang_left_text{
						margin-left: 0.27rem;
						p{
							&:nth-child(1){
								font-size: 0.2rem;
								color:#1A2533;
							}
							&:nth-child(2){
								font-size: 0.14rem;
								color:#1A2533;
								margin-top: 0.09rem;
							}
						}
					}
				}
				.youce_box_touxiang_right{
					span{
						padding: 0.05rem 0.1rem;
						border:1px solid #ccc;
						cursor: pointer;
						&:hover{
							background: #9a86db;
							color:#fff;
						}
					}
					i{
						color:#9a86db;
						margin:0 0.05rem;
						cursor: pointer;
					}
				}
			}
			.youce_xiangxi_xinxi{
				.xiangxi_tit{
					height:0.39rem;
					padding: 0 0.32rem;
					background: #f4f7fd;
					line-height: 0.6rem;
					font-size: 0.14rem;
					color:#1A2533;
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.transition-box{
					padding: 0.2rem 0;
					background: #f4f7fd;
					p{
						font-size: 0.12rem;
						color:#888888;
						padding: 0.05rem 0.2rem;
						span{
							font-size: 0.12rem;
							color:#4C4A4D;
						}
					}
					ul{
						li{
							border:0.01rem solid #ccc;
							border-left: none;
							border-right: none;
						}
					}
					img{
						width:100%;
					}
					.keyi_dianji{
						cursor: pointer;
						.fujian_xiazai_kuang,.guanlian_kuang{
							padding: 0.1rem 0.35rem;
							background: #c8c2c2;
							color:#1A2533;
							i{
								font-size: 0.2rem;
							}
						}
					}
					.tupian_kuang{
						width:30%;
						cursor: pointer;
					}
				}
			}
			.youce_box_con{
				p{
					font-size: 0.12rem;
					color:#888888;
					margin: 0.05rem 0;
					span{
						font-size: 0.12rem;
						color:#4C4A4D;
					}
				}
			}
			.liucheng{
				border-top:0.01rem solid #ccc;
				padding: 0 0.1rem;
				margin-bottom: 0.5rem;
				.liucheng_li{
					margin: 0.1rem 0;
					.liucheng_li_box{
						.liucheng_ren{
							min-height: 0.6rem;
							display: flex;
							justify-content: space-between;
							.liucheng_ren_box{
								display: flex;
								align-items: center;
								position: relative;
								.liucheng_touxiang{
									height:0.5rem;
									width:0.5rem;
									border-radius: 50%;
									background: #1890ff;
									color:#fff;
									font-size: 0.2rem;
									text-align: center;
									line-height: 0.5rem;
									font-family: NSimSun;
									margin-right:0.1rem;
								}
								span{
									position: absolute;
									display: block;
									bottom:0.05rem;
									left: 0.31rem;
									height:0.2rem;
									width:0.2rem;
									border-radius: 50%;
								}
								div{
									p{
										line-height: 0.22rem;
										&:nth-child(1){
											color:#191f25;
											font-size: 0.14rem;
										}
										&:nth-child(2){
											color:#7d8184;
											font-size: 0.12rem;
										}
									}
								}
							}
							.liucheng_time{
								font-size: 0.12rem;
								color:#a8abc1;
								margin-top: 0.1rem;
							}
						}
						.liucheng_pinglun{
							font-size: 0.12rem;
							color:#ccc;
							padding: 0 0.5rem;
						}
					}
					.liucheng_liebiao{
						min-height: 0.6rem;
						.liucheng_lb_box{
							display: flex;
							justify-content: space-between;
							.liucheng_liebiao_box{
								display: flex;
								align-items: center;
								position: relative;
								.liucheng_touxiang{
									height:0.5rem;
									width:0.5rem;
									border-radius: 50%;
									background: #1890ff;
									color:#fff;
									font-size: 0.2rem;
									text-align: center;
									line-height: 0.5rem;
									font-family: NSimSun;
									margin-right:0.1rem;
								}
								span{
									position: absolute;
									display: block;
									top:0.31rem;
									left: 0.31rem;
									height:0.2rem;
									width:0.2rem;
									border-radius: 50%;
								}
								div{
									p{
										line-height: 0.22rem;
										&:nth-child(1){
											color:#191f25;
											font-size: 0.14rem;
										}
										&:nth-child(2){
											color:#7d8184;
											font-size: 0.12rem;
										}
									}
								}
							}
							.liucheng_time{
								font-size: 0.12rem;
								color:#a8abc1;
								margin-top: 0.1rem;
							}
						}
						ul{
							display: flex;
							flex-wrap: wrap;
							margin-top: 0.1rem;
							padding: 0 0.6rem;
							li{
								width: 0.4rem;
								margin: 0 0.1rem;
								p{
									&:nth-child(1){
										height:0.4rem;
										width: 0.4rem;
										margin: 0 auto;
										border-radius: 50%;
										background: #1890ff;
										color:#fff;
										font-size: 0.2rem;
										text-align: center;
										line-height: 0.4rem;
										font-family: NSimSun;
									}
									&:nth-child(2){
										width:0.4rem;
										margin: 0 auto;
										overflow: hidden;/*超出部分隐藏*/
										white-space: nowrap;/*不换行*/
										text-overflow:ellipsis;/*超出部分文字以...显示*/
										font-size: 0.12rem;
										color:#7d8184;
									}
								}
							}
						}
					}
					.pinglun_zhanshi{
						margin: 0.3rem 0;
						div{
							display: flex;
							align-items: center;
							justify-content: space-between;
							p{
								font-size: 0.16rem;
								color: #191f25;
								span{
									color:#1890ff;
								}
							}
							.shijian{
								color:#a8abad;
							}
						}
						.pinglun_neirong{
							font-size: 0.14rem;
							color: #7d8184;
						}
					}
				}
			}
			// .pinglun_yangzi{
			// 	padding-bottom: 0.47rem;
			// 	li{
			// 		margin: 0.3rem 0;
			// 		padding: 0 0.1rem;
			// 		div{
			// 			display: flex;
			// 			align-items: center;
			// 			justify-content: space-between;
			// 			p{
			// 				font-size: 0.16rem;
			// 				color: #191f25;
			// 				span{
			// 					color:#1890ff;
			// 				}
			// 			}
			// 			.shijian{
			// 				color:#a8abad;
			// 			}
			// 		}
			// 		.pinglun_neirong{
			// 			font-size: 0.14rem;
			// 			color: #7d8184;
			// 		}
			// 	}
			// }
			.youce_box_foot{
				display: flex;
				position: fixed;
				bottom: 0;
				right:0;
				width:6.2rem;
				height:0.47rem;
				align-items: center;
				border-top:0.01rem solid #EEEEEE;
				justify-content: center;
				background: #fff;
				p{
					font-size: 0.12rem;
					color:#1A2533;
					margin: 0 0.3rem;
					cursor: pointer;
					&:nth-child(4){
						width:1.2rem;
						height:0.3rem;
						border-radius:0.02rem 0 0 0.02rem;
						border:0.01rem solid rgba(136,136,136,1);
						font-size: 0.12rem;
						text-align: center;
						line-height: 0.3rem;
						color:#888888;
						margin: 0;
						margin-left: 0.3rem;
					}
					&:nth-child(5){
						width:1.2rem;
						height:0.3rem;
						background:rgba(249,108,108,1);
						border-radius:0 0.02rem 0.02rem 0;
						text-align: center;
						margin: 0;
						line-height: 0.3rem;
						border:0.01rem solid #9a86db;
						color:#fff;
					}
				}
			}
		}
	}
	.pinglun{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		margin: 0;
		z-index:101;
		background: rgba(0,0,0,0.5);
		.box{
			height:4.43rem;
			width:5.9rem;
			background:#fff;
			position: relative;
			top:2.42rem;
			left:50%;
			margin-left: -2.95rem;
			.tit{
				display: flex;
				padding-top:0.1rem;
				justify-content: space-between;
				margin: 0 0.49rem;
				font-size: 0.14rem;
				color: #1A2533;
				span{
					cursor: pointer;
				}
			}
			.input{
				width:4.84rem;
				height:2.63rem;
				display: block;
				margin: 0 auto;
				padding:0.1rem 0.3rem;
				margin-top:0.3rem;
			}
			.foot{
				position: absolute;
				bottom: 0;
				left: 0;
				right:0;
				height:0.63rem;
				border-top:0.01rem solid #CCCCCC;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				span{
					padding: 0.06rem 0.47rem;
					font-size: 0.12rem;
					cursor: pointer;
					&:nth-child(1){
						color:#fff;
						border:0.01rem solid #9a86db;
						background: #9a86db;
						border-radius: 0.05rem 0 0 0.05rem;
					}
					&:nth-child(2){
						color:#888888;
						border:0.01rem solid #888888;
						background: #fff;
						margin-right: 0.2rem;
						border-radius: 0 0.05rem 0.05rem 0;
					}
				}
			}
		}
	}
}
</style>
